import React from 'react';
import HomeMain from './HomeMain';
import Footer from '../../components/Footer';

const HomePage = () => {
    return (
        <React.Fragment>
            <HomeMain />

			{/* Footer-start */}
			<Footer />
			{/* Footer-end */}
        </React.Fragment>
    );
}

export default HomePage;
