import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import Fade from 'react-reveal/Fade';

import videoImg from '../../assets/img/bg/video-bg.png';
import videoIcon from '../../assets/img/video-icon.svg';

const Video = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div id="tcd-video-area" className="tcd-video-area">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='yzCkM5MRaaI' onClose={() => { openModal(); }} />
            <div className="video-img">
                <img src={videoImg} alt="Video Bg Image" />
            </div>
            <div className="video-icon">
                <a id="play-home-video" className="video-play-button" href="#" onClick={() => { openModal(); }} >
                    <img src={videoIcon} alt="Video Icon" />
                </a>
                <p className="video-txt">Just as AMC Theaters took advantage</p>
            </div>
        </div>
    );
}

export default Video;