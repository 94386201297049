import React  from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";

import SingleTeam from '../../components/Team/SingleTeam';

// Categories Image
import teamImg1 from '../../assets/img/team/1.png';
import teamImg2 from '../../assets/img/team/2.png';
import teamImg3 from '../../assets/img/team/3.png';
import teamImg4 from '../../assets/img/team/4.png';

const Team = () => {

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                	slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                	slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <div id="tcd-team" className="tcd-team">
		    <div className="container">
		        <div className="sec-title top-border text-center pb-75 md-pb-50">
		            <h2 className="title">Developing Team</h2>
		            <p className="desc">There are many variation passages of Lorem Ipsum There are many variation passages of Lorem
		                Ipsum <br />passages of LoremIpsum available passages of LoremIpsum available...</p>
		        </div>
		        <div className="team-slider">
			        <Slider {...sliderSettings}>
			        	<SingleTeam 
			        		teamclassName='single-team'
			        		Image={teamImg1}
			        		Title='Herman Sheffield'
			        		Designation='Designer'
			        	/>
			        	<SingleTeam 
			        		teamclassName='single-team'
			        		Image={teamImg2}
			        		Title='Paul Walker'
			        		Designation='Designer'
			        	/>
			        	<SingleTeam 
			        		teamclassName='single-team'
			        		Image={teamImg3}
			        		Title='Kory Anderson'
			        		Designation='Designer'
			        	/>
			        	<SingleTeam 
			        		teamclassName='single-team'
			        		Image={teamImg4}
			        		Title='Katherine Davis'
			        		Designation='Designer'
			        	/>
			        </Slider>
		        </div>
		    </div>
		</div>
    );
}

export default Team;