
import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Logo from '../../assets/img/logo.svg';

const Footer = (props) => {
    const { footerLogo, footerClass} = props;
    return (
        <footer id="tcd-footer" className={footerClass ? footerClass : 'tcd-footer'}>
		    <div className="container">
		        <div className="row footer-content">
		            <div className="col-md-4">
		                <div className="footer-widget">
		                    <div className="footer-logo">
		                        <Link to="/">
		                            <img src={Logo} alt="Logo" />
		                        </Link>
		                    </div>
		                    <p className="copyright-text">© 2020 <a href="#">Budget.com</a>. All Rights Reserved.</p>
		                    <div className="footer-social">
		                        <ul className="social-icon">
		                            <li><a href="https://www.instagram.com/" target="_blank"><i className="fa fa-instagram"></i></a></li>
		                            <li><a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i></a></li>
		                            <li><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter"></i></a></li>
		                        </ul>
		                    </div>
		                </div>
		            </div>
		            <div className="col-md-4">
		                <div className="footer-widget">
		                    <h4 className="footer-title">Latest Information</h4>
		                    <p>Information Update</p>
		                    <ul className="footer-menu">
		                        <li><Link to="#">Home</Link></li>
		                        <li><Link to="#">About Us</Link></li>
		                        <li><Link to="#">Departent</Link></li>
		                        <li><Link to="#">Event</Link></li>
		                    </ul>
		                </div>
		            </div>
		            <div className="col-md-4">
		                <div className="footer-widget">
		                    <h4 className="footer-title">Contact Us</h4>
		                    <p>Social Address</p>
		                    <div className="tcd-newsletter">
		                        <form className="mb-0">
		                            <div className="form-group">
		                                <input type="email" name="your-email" placeholder="Your Email" />
		                                <button className="btn-newsletter"><i className="fa fa-paper-plane-o" aria-hidden="true"></i>
		                                </button>
		                            </div>
		                        </form>
		                    </div>

		                    <div className="footer-address">
		                        <a className="phone" href="tel:01234567">(+1) 212-946-2707</a><a href="mailto:Hello@agency.com">Hello@agency.com</a>
		                        <span>12W 34th St, bangladesh</span>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
        </footer>
    );
}

export default Footer;