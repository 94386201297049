import React from 'react';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import SingleBlog from '../../components/Blog/SingleBlog';

import blogImg1 from '../../assets/img/blog/1.jpg';
import blogImg2 from '../../assets/img/blog/2.jpg';
import blogImg3 from '../../assets/img/blog/3.jpg';

const Blog = () => {

    const blogSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div id="tcd-blog" className="tcd-blog sec-spacer">
                <div className="container-fluid">
                    <div className="sec-title top-border text-center pb-75 md-pb-50">
                        <h2 className="title">Our Blog Post</h2>
                        <p className="desc">There are many variation passages of Lorem Ipsum There are many variation passages of Lorem Ipsum <br />passages of LoremIpsum available passages of LoremIpsum available...</p>
                    </div>
                    <div className="blog-slider">
                        <Slider {...blogSettings}>
                            <SingleBlog 
                                blogClass='blog-item'
                                blogImg={blogImg1}
                                blogTitle='New Chicago school budget relies on the state pension' 
                                blogDesc='There are many variation passages of Lorem Ipsum There are many variation passages oform pasages of Ipsum available...' 
                                btnText='Read More'
                                blogDate='25'
                                blogMonth='May'
                                likesCount='10'
                                commentsCount='08'
                            />
                            <SingleBlog 
                                blogClass='blog-item'
                                blogImg={blogImg2}
                                blogTitle='New Chicago school budget relies on the state pension' 
                                blogDesc='There are many variation passages of Lorem Ipsum There are many variation passages oform pasages of Ipsum available...' 
                                btnText='Read More'
                                blogDate='25'
                                blogMonth='May'
                                likesCount='10'
                                commentsCount='08'
                            />
                            <SingleBlog 
                                blogClass='blog-item'
                                blogImg={blogImg3}
                                blogTitle='New Chicago school budget relies on the state pension' 
                                blogDesc='There are many variation passages of Lorem Ipsum There are many variation passages oform pasages of Ipsum available...' 
                                btnText='Read More'
                                blogDate='25'
                                blogMonth='May'
                                likesCount='10'
                                commentsCount='08'
                            />
                            <SingleBlog 
                                blogClass='blog-item' 
                                blogImg={blogImg2}
                                blogTitle='New Chicago school budget relies on the state pension' 
                                blogDesc='There are many variation passages of Lorem Ipsum There are many variation passages oform pasages of Ipsum available...' 
                                btnText='Read More'
                                blogDate='25'
                                blogMonth='May'
                                likesCount='10'
                                commentsCount='08'
                            />
                        </Slider>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Blog;