import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import whiteLogo from '../../assets/img/logo-white.svg';
import phoneLogo from '../../assets/img/arrows/phone.svg';

const Header = () => {	
    const [menuOpen, setMenuOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

    const [home, setHome] = useState(true)
    const [about, setAbout] = useState(false)
    const [services, setServices] = useState(false)
    const [features, setFeatures] = useState(false)
    const [blog, setBlog] = useState(false)
    const [contact, setContact] = useState(false)
    
    const addActiveClass = (menu) => {
        if (menu == 'home') {
            setHome(!home)
            setAbout(false)
            setServices(false)
            setFeatures(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu == 'about') {
            setHome(false)
            setAbout(!about)
            setServices(false)
            setFeatures(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu == 'services') {
            setHome(false)
            setAbout(false)
            setServices(!services)
            setFeatures(false)
            setBlog(false)
            setContact(false)
        }
        else if (menu == 'features') {
            setHome(false)
            setAbout(false)
            setServices(false)
            setFeatures(!features)
            setBlog(false)
            setContact(false)
        }
        else if (menu == 'blog') {
            setHome(false)
            setAbout(false)
            setServices(false)
            setFeatures(false)
            setBlog(!blog)
            setContact(false)
        }
        else if (menu == 'contact') {
            setHome(false)
            setAbout(false)
            setServices(false)
            setFeatures(false)
            setBlog(false)
            setContact(!contact)
        }
    };

	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);


	return (
		<React.Fragment>
			<header className="tcd-header-1">
		        <div className={isVisible ? 'menu-bar menu-sticky sticky' : 'menu-bar menu-sticky'}>
		            <div className="container">
		                <div className="row">
		                    <div className="col-lg-3 relative">
		                        <div className="logo">
		                        	<Link to="/">
										<img src={whiteLogo} alt="Logo" />
									</Link>
		                        </div>
		                        <button className={menuOpen ? "mobile-menu-icon open" : "mobile-menu-icon"} aria-label="Main Menu" onClick={() => {setMenuOpen(!menuOpen)}}>
		                            <span></span>
		                            <span></span>
		                            <span></span>
		                        </button>
		                    </div>
		                    <div className="col-lg-9 relative">
		                        <div className={menuOpen ? "mainmenu menu-open" : "mainmenu"}>
		                            <ul className="nav-menu">
		                                <li className="nav-item"><a className={home ? "active" : ""} onClick={() => { addActiveClass('home'); document.getElementById("tcd-banner").scrollIntoView({behavior: 'smooth'}) } } >Home</a></li>

			                            <li className="nav-item"><a className={about ? "active" : ""} onClick={() => { addActiveClass('about'); document.getElementById("tcd-about").scrollIntoView({behavior: 'smooth'}) } } >About</a></li>

			                            <li className="nav-item"><a className={services ? "active" : ""} onClick={() => { addActiveClass('services'); document.getElementById("tcd-services").scrollIntoView({behavior: 'smooth'}) } } >Services</a></li>

			                            <li className="nav-item"><a className={features ? "active" : ""} onClick={() => { addActiveClass('features'); document.getElementById("tcd-features").scrollIntoView({behavior: 'smooth'}) } } >Features</a></li>

			                            <li className="nav-item"><a className={blog ? "active" : ""} onClick={() => { addActiveClass('blog'); document.getElementById("tcd-blog").scrollIntoView({behavior: 'smooth'}) } } >Blog</a></li>

			                            <li className="nav-item"><a className={contact ? "active" : ""} onClick={() => { addActiveClass('contact'); document.getElementById("tcd-footer").scrollIntoView({behavior: 'smooth'}) } }>Contact</a></li>
			                        </ul>
		                            <div className="contact-menu">
		                                <span className="icon"><img src={phoneLogo} alt="Phone Icon" /> </span>
		                                <div className="contact-number">
		                                    <span><a className="phone" href="tel:01234567">(+1) 212-946-2707</a></span>
		                                    <span>Opening Hours 09-05</span>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		            <div className="menu-bg hidden-md"></div>
		        </div>
		    </header>
		</React.Fragment>
	);
}

export default Header;