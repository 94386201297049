import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import whiteLogo from '../../assets/img/logo-white.svg';
import phoneLogo from '../../assets/img/arrows/phone.svg';

const Header = () => {	
    const [menuOpen, setMenuOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);


	return (
		<React.Fragment>
			<header className="tcd-header-1">
		        <div className={isVisible ? 'menu-bar menu-sticky sticky' : 'menu-bar menu-sticky'}>
		            <div className="container">
		                <div className="row">
		                    <div className="col-lg-3 relative">
		                        <div className="logo">
		                        	<Link to="/">
										<img src={whiteLogo} alt="Logo" />
									</Link>
		                        </div>
		                        <button className={menuOpen ? "mobile-menu-icon open" : "mobile-menu-icon"} aria-label="Main Menu" onClick={() => {setMenuOpen(!menuOpen)}}>
		                            <span></span>
		                            <span></span>
		                            <span></span>
		                        </button>
		                    </div>
		                    <div className="col-lg-9 relative">
		                        <div className={menuOpen ? "mainmenu menu-open" : "mainmenu"}>
		                            <ul className="nav-menu">
		                                <li className="nav-item"><a href="#">Home</a></li>

			                            <li className="nav-item"><a href="#">About</a></li>

			                            <li className="nav-item"><a href="#">Services</a></li>

			                            <li className="nav-item"><a href="#">Features</a></li>

			                            <li className="nav-item"><a href="#">Blog</a></li>

			                            <li className="nav-item"><a href="#">Contact</a></li>
			                        </ul>
		                            <div className="contact-menu">
		                                <span className="icon"><img src={phoneLogo} alt="Phone Icon" /> </span>
		                                <div className="contact-number">
		                                    <span><a className="phone" href="tel:01234567">(+1) 212-946-2707</a></span>
		                                    <span>Opening Hours 09-05</span>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		            <div className="menu-bg hidden-md"></div>
		        </div>
		    </header>
		</React.Fragment>
	);
}

export default Header;