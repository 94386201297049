import React from 'react';
import { Link } from 'react-router-dom';

const SingleFeature = (props) => {
	const { itemClass, Image, Title, Desc } = props;
	return(
        <div className={itemClass ? itemClass : 'feature-item'}>
            <div className="feature-img">
                <img 
                    src={Image} 
                    alt="Image Alt" 
                /> 
            </div>
            <div className="feature-text">
                <span className="water-text">01</span>
                <h4 className="title">{Title ? Title : 'Materials Technology'}</h4>
                <span className="desc">{Desc ? Desc : 'Aliquet quia autem maecenas, digniss inceptos axime eveniet'}</span>
            </div>
        </div>
	)
}

export default SingleFeature