import React from 'react';

const Banner = () => {

    return (
        <div className="blog-banner overlay">
            <div className="container">
                <div className="banner_inner d-flex align-items-center justify-content-center">
                    <div className="banner_content">
                        <h2>Blog Details</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur <br />adipisicing elit. Lorem ipsome dolar Inventore, incidunt...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
