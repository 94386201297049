import { Link } from 'react-router-dom';

const SingleTeam = (props) => {
    const { teamClass, Image, Title, Designation } = props;
	return(
        <div className={teamClass ? teamClass : 'single-team'}>
            <div className="team-img">
                <img 
                    src={Image} 
                    alt={Title}
                />
            </div>
            <div className="team-text">
                <span className="title">{Title ? Title : 'Herman Sheffield'}</span>
                <span className="designation">{Designation ? Designation : 'Designer'}</span>
                <div className="hidden-content">
                    <span className="desc">Aenean animi error sint iusto hynae</span>
                    <ul className="social-icon">
                        <li><Link to="https://www.google.com/" target="_blank"><i className="fa fa-google"></i></Link></li>
                        <li><Link to="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i></Link></li>
                        <li><Link to="https://twitter.com/" target="_blank"><i className="fa fa-twitter"></i></Link></li>
                    </ul>
                </div>
            </div>
        </div>
	)
}

export default SingleTeam