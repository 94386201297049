
import { Link } from 'react-router-dom';

const SingleBlog = (props) => {
    const { blogClass, blogImg, blogTitle, blogDesc, btnText, blogDate, blogMonth, likesCount, commentsCount  } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item'}>
            <div className="blog-img">
                <Link to="/blog/blog-details">
                    <img 
                        src={blogImg} 
                        alt={blogTitle}
                    />
                </Link>
            </div>
            <div className="blog-content">
                <div className="left-part">
                    <div className="single-part">
                        <span className="date">{blogDate ? blogDate : '25'}</span>
                        <span className="date">{blogMonth ? blogMonth : 'May'}</span>
                    </div>
                    <div className="single-part">
                        <span>Likes: {likesCount ? likesCount : '10'}</span>
                    </div>
                    <div className="single-part">
                        <span>{commentsCount ? commentsCount : '08'}</span>
                        <span>Comments</span>
                    </div>
                </div>
                <div className="right-part">
                    <h4 className="title"><Link to="/blog/blog-details">{blogTitle ? blogTitle : 'New Chicago school budget relies on the state'}</Link></h4>
                    <p className="desc">{blogDesc ? blogDesc : 'There are many variation passages of Lorem Ipsum There are many variation passages oform pasages of Ipsum available...'}</p>
                    <Link to="/blog/blog-details" className="blog-btn">{btnText ? btnText : 'Read More'}</Link>
                </div>
            </div>
        </div>

    )
}

export default SingleBlog