import React, { Component } from 'react';
import Team from './TeamSection';
import Blog from './BlogSection';
import Video from './VideoSection';
import About from './AboutSection';
import Feature from './FeatureSection';
import Service from './ServiceSection';
import HomeBanner from './BannerSection';
import Testimonial from './TestimonialSection';
import ScrollToTop from '../../components/ScrollTop';

const HomeMain = () => {
	return (
		<React.Fragment>
			{/* SliderSection-start */}
			<HomeBanner />
			{/* SliderSection-start */}

			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}

			{/* Video-area-start */}
			<Video />
			{/* Video-area-end */}

			{/* Feature-area-start */}
			<Feature />
			{/* Feature-area-end */}

			{/* Team-area-start */}
			<Team />
			{/* Team-area-end */}

			{/* blog-area-start */}
			<Blog />
			{/* blog-area-end */}

			{/* testmonial-area-start */}
			<Testimonial />
			{/* testmonial-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;