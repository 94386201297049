import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

import authorImg from '../../assets/img/blog/author.jpg';
import recentImg1 from '../../assets/img/blog/recent_1.jpg';
import recentImg2 from '../../assets/img/blog/recent_2.jpg';
import recentImg3 from '../../assets/img/blog/recent_3.jpg';
import arrowIcon from '../../assets/img/arrows/icon.png';
import addImg from '../../assets/img/blog/add.png';
import feedImg1 from '../../assets/img/blog/feed_1.jpg';
import feedImg2 from '../../assets/img/blog/feed_2.jpg';
import feedImg3 from '../../assets/img/blog/feed_3.jpg';
import feedImg4 from '../../assets/img/blog/feed_4.jpg';
import feedImg5 from '../../assets/img/blog/feed_5.jpg';
import feedImg6 from '../../assets/img/blog/feed_6.jpg';
import feedImg7 from '../../assets/img/blog/feed_7.jpg';
import feedImg8 from '../../assets/img/blog/feed_8.jpg';
import feedImg9 from '../../assets/img/blog/feed_9.jpg';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="blog_right_sidebar">
            <aside className="single_sidebar_widget search_widget">
                <form>
                    <div className="input-group mb-30">
                        <input type="text" className="form-control" placeholder="Search Posts" />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="button"><i className="fa fa-search"></i></button> 
                        </span>
                    </div>
                </form>
            </aside>

            <aside className="single_sidebar_widget author_widget">
                <img className="img-fluid mb-30" src={authorImg} alt="" />
                <div className="author_content">
                    <div className="widget_title">
                        <h4><a href="#">Diana Sheperson</a></h4>
                        <p>Author</p>
                    </div>
                    <div className="author_decs">
                        <p>Lorem ipsum dolor sit amet, cons ectetur adip isicing elit, sed do eius mod
                            tempor incidid untut labore etexai dolore magna. Sed do eius mod tempor labore.</p>
                    </div>
                </div>
            </aside>

            <aside className="single_sidebar_widget popular_post_widget">
                <div className="widget_title">
                    <h4><a href="#">Popular Posts</a></h4>
                    <p>Selected through contest</p>
                </div>
                <div className="post_items">
                    <div className="media">
                        <img src={recentImg1} alt="post" />
                        <div className="media-body">
                            <div className="blog_info">
                                <ul className="blog_meta list">
                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                    <li><a href="#"><i className="lnr lnr-bubble"></i>03</a></li>
                                </ul>
                            </div>
                            <a href="#">
                                <h5>Why you should take a leave from your life now</h5>
                            </a>
                        </div>
                    </div>
                    <div className="br"></div>
                    <div className="media">
                        <img src={recentImg2} alt="post" />
                        <div className="media-body">
                            <div className="blog_info">
                                <ul className="blog_meta list">
                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                    <li><a href="#"><i className="lnr lnr-bubble"></i>04</a></li>
                                </ul>
                            </div>
                            <a href="#">
                                <h5>Why you should take a leave from you life now</h5>
                            </a>
                        </div>
                    </div>
                    <div className="br"></div>
                    <div className="media">
                        <img src={recentImg3} alt="post" />
                        <div className="media-body">
                            <div className="blog_info">
                                <ul className="blog_meta list">
                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                    <li><a href="#"><i className="lnr lnr-bubble"></i>07</a></li>
                                </ul>
                            </div>
                            <a href="#">
                                <h5>Why you should take a leave from your life now</h5>
                            </a>
                        </div>
                    </div>
                </div>
            </aside>

            <aside className="single_sidebar_widget video_widget">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='yzCkM5MRaaI' onClose={() => { openModal(); }} />
                <div className="widget_title">
                    <h4><a href="#">Popular Videos</a></h4>
                    <p>Selected through contest</p>
                </div>
                <div className="video_content">
                    <a id="play-home-video" className="video-play-button" href="#"  onClick={() => { openModal(); }}>
                        <img className="img-fluid" src={arrowIcon} alt="Image Alt" />
                    </a>

                </div>
            </aside>

            <aside className="single_sidebar_widget post_category_widget">
                <div className="widget_title">
                    <h4>Post Categories</h4>
                    <p>Several categories to browse</p>
                </div>
                <ul className="list cat-list">
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>Design Tutorial</p>
                            <p>(13)</p>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>Featured Blog</p>
                            <p>(23)</p>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>Arts & Entertainment</p>
                            <p>(59)</p>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>News & Events</p>
                            <p>(29)</p>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>Lifestyle</p>
                            <p>(15)</p>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="d-flex justify-content-between">
                            <p>Travel</p>
                            <p>(09)</p>
                        </a>
                    </li>
                </ul>
            </aside>

            <aside className="single_sidebar_widget ads_widget">
                <div className="add_img_box">
                    <img className="img-fluid" src={addImg} alt="Add-Img" />
                </div>
            </aside>

            <aside className="single-sidebar-widget newsletter_widget">
                <div className="widget_title">
                    <h4><a href="#">Newsletter</a></h4>
                    <p>Several categories to browse</p>
                </div>
                <div className="news_desc">
                    <p>Lorem ipsum dolor sit amet, cons ectetur adip isicing elit, sed do eius mod tempor
                        incidid untut labore etexai dolore magna.</p>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Enter email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address'" />
                    </div>
                    <a href="#" className="primary-btn">Subcribe</a>
                </div>
            </aside>

            <aside className="single-sidebar-widget tag_cloud_widget">
                <div className="widget_title">
                    <h4><a href="#">Tag Clouds</a></h4>
                    <p>Several categories to browse</p>
                </div>
                <ul className="list">
                    <li><a href="#">Food</a></li>
                    <li><a href="#">Fashion</a></li>
                    <li><a href="#">Technology</a></li>
                    <li><a href="#">Apps & Games</a></li>
                    <li><a href="#">Movie</a></li>
                    <li><a href="#">Top</a></li>
                    <li><a href="#">Music</a></li>
                    <li><a href="#">Fashion</a></li>
                    <li><a href="#">Interior</a></li>
                </ul>
            </aside>
            
            <aside className="single-sidebar-widget feed_widget">
                <div className="widget_title">
                    <h4><a href="#">Instafeed</a></h4>
                    <p>Several categories to browse</p>
                </div>
                <div className="row feed_box">
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg1} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg2} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg3} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg4} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg5} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg5} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg6} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg7} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-2 col-sm-2 col-4">
                        <div className="feed_img">
                            <a href="#"><img className="img-fluid" src={feedImg8} alt="" /></a>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    );
}

export default Sidebar;
