import React  from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import aboutImg from '../../assets/img/about/about.png';

const About = () => {

    return (
        <div id="tcd-about" className="tcd-about sec-spacer">
		    <div className="row align-items-center margin-0">
		        <div className="col-lg-6 padding-0">
		            <div className="about-img">
		                <img src={aboutImg} alt="About Image" />
		            </div> 
		        </div>
		        <div className="col-lg-6 padding-0">
		            <div className="about-content">
		                <h2 className="mb-25">Learn About Us</h2>
		                <p className="mb-20">A collection of textile samples lay spread out on the table - Samsa was a travelling
		                    salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine
		                    and housed in a nice.</p>
		                <p className="mb-0">It showed a lady fitted out with a fur hat and fur boa who sat upright</p>
		            </div>
		        </div>
		    </div>
		</div>
    );
}

export default About;