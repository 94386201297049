import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import Sidebar from './SidebarPart';

//Images
import postImg1 from '../../assets/img/blog/post_8.jpg';
import postImg2 from '../../assets/img/blog/post_sm13.jpg';
import postImg3 from '../../assets/img/blog/post_sm14.jpg';
import postImg4 from '../../assets/img/blog/post_sm15.jpg';
import authorImg1 from '../../assets/img/blog/client_img1.png';
import authorImg2 from '../../assets/img/blog/client_img2.png';
import authorImg3 from '../../assets/img/blog/client_img3.png';
import authorImg4 from '../../assets/img/blog/client_img4.png';
import authorImg5 from '../../assets/img/blog/client_img5.png';
import likeImg1 from '../../assets/img/blog/like_img1.jpg';
import likeImg2 from '../../assets/img/blog/like_img2.jpg';
import likeImg3 from '../../assets/img/blog/like_img3.jpg';
import likeImg4 from '../../assets/img/blog/like_img4.jpg';



const BlogDetailsMain = () => {

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    
    return (
        <div className="blog_area pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 order-last">
                        <Sidebar />
                    </div>
                    <div className="col-lg-8">
                        <div className="blog_left_sidebar">
                            <article className="blog_left_sidebar_item">
                                <div className="blog_post">
                                    <div className="blog_details">
                                        <a className="primary-btn primary_bg" href="#">Travel Tour</a>
                                        <div className="blog_info">
                                            <ul className="blog_meta list">
                                                <li><a href="#"><i className="lnr lnr-user"></i>Hugo Thomas</a></li>
                                                <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Feb, 2019</a></li>
                                                <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                            </ul>
                                        </div>
                                        <a href="#">
                                            <h2>Top 20 Finance Job Titles | Ongoig Blog 2020</h2>
                                        </a>
                                        <h4 className="mb-30">Integer phasellus vel id nisi vulputate platea dui
                                            lacinia.Posuere dictumst quis auctor lib ero magna musin a dignissim duisaptent leo lobortis egestas nibh dictumst luctus non, aliquet pede urna pede nascetur facilisi.</h4>
                                        <div className="blog_img_box mb-30">
                                            <img src={postImg1} alt="" />
                                        </div>
                                        <p>Dolor sit amet, consectetuer adipiscing elit sed diam nonummy nibh euismod
                                            tincidunt laoreet dolor magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullami mcorper suscipit lobortis nisl ut aliquip exea commodo cras nihl preadend pub orci.</p>
                                        <div className="capitalize mb-20">
                                            <p>Capitalize on low hanging fruit to identify a ballpark value added
                                                activity to beta test. Overri de the digital divide with additional clickthroughs from via premier niche markets.</p>
                                        </div>
                                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo con sequat. Duis aute rure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                                        <p>Sed diam nonummy nibh euismod tincidunt laoreet dolore magna aliquam eratv
                                            olutpat. Ut wisi enim minim veniam quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip exea commodo cras nihl preadend pub orci. Bulimia in an attempt llamcorper suscipit lobortis nisl ut aliquip Acidunt ut laobr dolore magna aliquam erat volutpat. Ut wisi enim minim veniam, quis nostrud exerci tation ullamcorper</p>
                                    </div>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <div className="self_host_area">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="list_document">
                                                <ul>
                                                    <li>
                                                        <p>Self-hosted blogs (WordPress.org), while requiring hosting
                                                            and your own domain name offer unbeatable advantages.</p>
                                                    </li>
                                                    <li>
                                                        <p>That most of the top free blog sites just can’t compete with. So,I definitely recommend them for business websites. </p>
                                                    </li>
                                                    <li>
                                                        <p>But free blogging platforms offer a lot of benefits too as long as you choose the right one.</p>
                                                    </li>
                                                </ul>
                                                <p>Dolor sit amet, consect etuer adipi scing sed diam nonummy nibh euism tincidunt laoreet dolor magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullami mcorper suscipit lobor tis nisl ut aliquip exea commodo cras nihl preadend pub orci.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-7 col-8">
                                            <div className="image_box md-mb-30">
                                                <img className="img-fluid" src={postImg2} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className="mb-20">Completely Synergize Resource Taxing Relationships</h4>
                                    <p className="mb-30">IPhosfluorescently engage worldwide methodologies with web-enabled technology. Interactively coordi nate proactive sites is e - commerce via process - centric “outside the box” thinking. Completely pursue scalable customer service through sustainable potentialities. Collaboratively administrate turnkey chan nels whereas virtual e-tailers.</p>

                                    <p className="mb-30">Professionally cultivate one-to-one customer service with robust
                                        ideas. Dynamically innovate resource- leveling need customer service for state of the art customer service.</p>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <div className="advice_area">
                                    <h4 className="mb-20">Advice From Twenty Something</h4>
                                    <p>Lorem ipsum dosectetur adipisicing elit, sed do.Lorem ipsum dolor sit amet,
                                        consectetur Nulla fringilla purus at leo dignis sim congue. Mauris elementum accumsan leo vel tempor.Sit amet cursu nisl aliqua. Aliquam et elit eu nunc rhoncus viverra quis at felis. Sed do.Lorem ipsum dolor sit amet.</p>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="add_img_box xs-mb-30">
                                                <img src={postImg3} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="add_img_box">
                                                <img src={postImg4} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5>Aliquam etelit eu nunc rhoncus viverra quis felis etnetus malesuada fames turpis egestas. Aenean commod ligula eget dolor. Aenean massa cum sociis natoq penatibus et magnis parturient montes.</h5>
                                    <p className="mb-30">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisiut aliquip exea commodo cons equat.Duis aut eirure dolor in reprehenderit voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupid atat non proident, sunt in culpa qui officia deserunt mollit anim id est aborum.d</p>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <div className="about_author-area">
                                    <div className="tag_catagorie mb-30">
                                        <a href="#">
                                            <h4>Tags :</h4>
                                        </a>
                                        <a href="#">Technology ,</a>
                                        <a href="#">Design ,</a>
                                        <a href="#">Featured Blog .</a>
                                    </div>
                                    <h4 className="mb-20">About Author</h4>
                                    <div className="author_item pb-50">
                                        <div className="single-author justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src={authorImg1} alt="" />
                                                </div>
                                                <div className="desc mt-20">
                                                    <p className="mb-20">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut labor dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    <div className="author_social_icon">
                                                        <ul>
                                                            <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-behance"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <div className="comments-area">
                                    <div className="comment_title justify-content-between d-flex mb-20">
                                        <h3>Comments (13)</h3>
                                        <div className="show_all">
                                            <a href="#show_all">Show all <i className="fa fa-angle-down"></i></a>
                                        </div>
                                    </div>
                                    <div className="comment-list">
                                        <div className="single-comment justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src={authorImg2} alt="" />
                                                </div>
                                                <div className="desc">
                                                    <h5><a href="#">James Jeferson</a></h5>
                                                    <p className="date">2nd Oct, 2018</p>
                                                    <p className="comment">
                                                        Pro in soleat regione assueverit, id vis tation adipisci euripidis. Quis solet urbanitas an nam. Ei eos dissentias contentiones, ei qui impetus eruditi. Dolor viderer
                                                        vivendum pro eu, ne case reprehendunt vim, et his quod officiis sententiae.
                                                    </p>
                                                    <a href="" className="comment_btn">Reply</a>
                                                    <a href="" className="comment_btn">Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-list left-padding">
                                        <div className="single-comment justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src={authorImg3} alt="" />
                                                </div>
                                                <div className="desc">
                                                    <h5><a href="#">Jane Thomas</a></h5>
                                                    <p className="date">2nd Oct, 2018</p>
                                                    <p className="comment">
                                                        Quis solet urbanitas an nam. Ei eos dissentia contentiones eiqui impetus eruditi. Dolor viderer vivendum pro eune case reprehendunt vim ethis quod officiis.
                                                    </p>
                                                    <a href="" className="comment_btn">Reply</a>
                                                    <a href="" className="comment_btn">Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-list">
                                        <div className="single-comment justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src={authorImg4} alt="" />
                                                </div>
                                                <div className="desc">
                                                    <h5><a href="#">Lucy Parker</a></h5>
                                                    <p className="date">4th Oct, 2018</p>
                                                    <p className="comment">
                                                        Pro in soleat regione assueverit, id vis tation adipisci euripidis. Quis solet urbanitas an nam. Ei eos dissentias contentiones, ei qui impetus eruditi. Dolor viderer
                                                        vivendum pro eu, ne case reprehendunt vim, et his quod officiis sententiae.
                                                    </p>
                                                    <a href="" className="comment_btn">Reply</a>
                                                    <a href="" className="comment_btn">Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-list left-padding">
                                        <div className="single-comment justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb">
                                                    <img src={authorImg5} alt="" />
                                                </div>
                                                <div className="desc">
                                                    <h5><a href="#">Diana Seperson</a></h5>
                                                    <p className="date">4th Oct, 2018</p>
                                                    <p className="comment">
                                                        Quis solet urbanitas an nam. Ei eos dissentia contentiones eiqui impetus eruditi. Dolor viderer vivendum pro eune case reprehendunt vim ethis quod officiis.
                                                    </p>
                                                    <a href="" className="comment_btn">Reply</a>
                                                    <a href="" className="comment_btn">Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <div className="comment-form">
                                    <h3>Leave a Reply</h3>
                                    <p>Your email address will not be published. Required fields are marked *</p>
                                    <form>
                                        <div className="form-group">
                                            <textarea className="form-control mb-10" rows="5" name="message" placeholder="Comment" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Comment'" required></textarea>
                                        </div>
                                        <div className="row form-group form-inline">
                                            <div className="form-group col-lg-6 col-md-6 name">
                                                <input type="text" className="form-control" id="name" placeholder="Name *" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Name *'" />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 email">
                                                <input type="email" className="form-control" id="email" placeholder="Email *" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email *'" />
                                            </div>
                                        </div>
                                        <a href="#" className="primary-btn primary_btn mt-30">Post Your Comment</a>
                                    </form>
                                </div>
                            </article>

                            <article className="blog_left_sidebar_item">
                                <h3 className="mt-45 mb-10">You may like </h3>
                                <div className="row">
                                    <div className="related_post_slide">
                                        <Slider {...sliderSettings}>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg3} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st
                                                                        Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg4} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Sed ut perspiciatis, unde omnis iste natus error sit volup tatem accusa ntium </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg1} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg2} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Sed ut perspiciatis, unde omnis iste natus error sit volup tatem accusa ntium </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg3} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single_blog_item2">
                                                <div className="related_post_items">
                                                    <div className="blog_post">
                                                        <div className="blog_img_box mb-25">
                                                            <img src={likeImg4} alt="" />
                                                        </div>
                                                        <div className="blog_details">
                                                            <div className="blog_info">
                                                                <ul className="blog_meta list">
                                                                    <li><a href="#"><i className="lnr lnr-calendar-full"></i>1st Oct, 2018</a></li>
                                                                    <li><a href="#"><i className="lnr lnr-bubble"></i>Comments</a></li>
                                                                </ul>
                                                            </div>
                                                            <a href="#">
                                                                <h3>Private Guide Tour</h3>
                                                            </a>
                                                            <p>Sed ut perspiciatis, unde omnis iste natus error sit volup tatem accusa ntium </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogDetailsMain;