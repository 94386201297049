import React from 'react';
import Banner from './BannerPart';
import Header from './HeaderSection';
import Footer from '../../components/Footer';
import BlogDetailsMain from './BlogDetailsMain';
import ScrollToTop from '../../components/ScrollTop';

const BlogDetails = () => {
    return (
        <React.Fragment>
            <Header />

            <Banner />

            {/* Blog Main */}
            <BlogDetailsMain />
            {/* Blog Main End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <Footer />
        </React.Fragment>
    );
}


export default BlogDetails;

