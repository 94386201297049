
const SingleTestimonial = (props) => {
	const { itemClass, itemImg, quoteImg, Title, Designation, Desc } = props;
    return (
        <div className={itemClass ? itemClass : 'testi-item'}>
            <div className="testi-img">
                <img 
                    src= {itemImg} 
                    alt="Backey Tompson"
                    className='img'
                />
                <div className="quote">
                    <img 
                        src= {quoteImg} 
                        alt="Backey Tompson"
                    />
                </div>
            </div>
            <div className="testi-info">
                <h6 className="title">{Title ? Title : 'Kori Anderson'}</h6>
                <span className="designation">{Designation ? Designation : 'Founder'}</span>
            </div>
            <div className="testi-desc">
                <p>{Desc ? Desc : 'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem ipsum...'}</p>
            </div>
        </div>
    )
}

export default SingleTestimonial