import React from 'react';
import Fade from 'react-reveal/Fade';

import SingleService from '../../components/Service/SingleService';

import serviceImg1 from '../../assets/img/services/1.svg';
import serviceImg2 from '../../assets/img/services/2.svg';
import serviceImg3 from '../../assets/img/services/3.svg';

const Service = () => {

    return (
        <div id="tcd-services" className="tcd-services">
		    <div className="container">
		        <div className="sec-title top-border text-center pb-120">
		            <h2 className="title">Services We Provide</h2>
		            <p className="desc">There are many variation passages of Lorem Ipsum There are many variation passages of Lorem Ipsum <br />passages of LoremIpsum available passages of LoremIpsum available...</p>
		        </div>
		        <div className="row">
		            <div className="col-lg-4 md-mt-80">
		            	<SingleService 
		            		itemClass='single-service'
		            		Icon={serviceImg1}
		            		Title='Business Insurance'
		            		Desc='There are many variation passages of sum passages available there are many variation passages take some moment available.'
		            	/>
		            </div>
		            <div className="col-lg-4 md-mt-80">
		            	<SingleService 
		            		itemClass='single-service'
		            		Icon={serviceImg2}
		            		Title='Finance Management'
		            		Desc='There are many variation passages of sum passages available there are many variation passages take some moment available.'
		            	/>
		            </div>
		            <div className="col-lg-4">
		            	<SingleService 
		            		itemClass='single-service'
		            		Icon={serviceImg3}
		            		Title='Business Analysis'
		            		Desc='There are many variation passages of sum passages available there are many variation passages take some moment available.'
		            	/>
		            </div>
		        </div>
		    </div>
		</div>
    );
}

export default Service;