import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";

import SingleFeature from '../../components/Feature/SingleFeature';

// Categories Image
import featureImg1 from '../../assets/img/features/1.jpg';
import featureImg2 from '../../assets/img/features/2.jpg';
import featureImg3 from '../../assets/img/features/3.jpg';
import featureImg4 from '../../assets/img/features/3.jpg';

const Feature = () => {
	
    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                	slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                	slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <div id="tcd-features" className="tcd-features sec-spacer">
		    <div className="container">
		        <div className="sec-title top-border text-center pb-75 md-pb-50">
		            <h2 className="title">Our Best Feature</h2>
		            <p className="desc">There are many variation passages of Lorem Ipsum There are many variation passages of Lorem Ipsum <br />passages of LoremIpsum available passages of LoremIpsum available...</p>
		        </div>
		        <div className="feature-slider">
			        <Slider {...sliderSettings}>
				        <SingleFeature 
				        	itemClass='feature-item'
				        	Image={featureImg1}
				        	Title='Materials Technology'
				        	Desc='Relique quia autem maecenas, digniss inceptos axime eveniet'
				        />
				        <SingleFeature 
				        	itemClass='feature-item'
				        	Image={featureImg2}
				        	Title='Startup Funding'
				        	Desc='Relique quia autem maecenas, digniss inceptos axime eveniet'
				        />
				        <SingleFeature 
				        	itemClass='feature-item'
				        	Image={featureImg3}
				        	Title='Business Matching'
				        	Desc='Relique quia autem maecenas, digniss inceptos axime eveniet'
				        />
				        <SingleFeature 
				        	itemClass='feature-item'
				        	Image={featureImg4}
				        	Title='Financial Advisory'
				        	Desc='Relique quia autem maecenas, digniss inceptos axime eveniet'
				        />
			        </Slider>
		        </div>
		    </div>
		</div>
    );
}

export default Feature;