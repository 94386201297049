import React from 'react';
import { Link } from 'react-router-dom';

const SingleService = (props) => {
	const { itemClass, Icon, Title, Desc } = props;
	return(
        <div className={itemClass ? itemClass : 'single-service'}>
            <div className="service-icon">
                <img src={Icon} alt="Service Icon" />
            </div>
            <div className="service-text">
                <h4 className="title">{Title ? Title : 'Business Insurance'}</h4>
                <p className="desc">{Desc ? Desc : 'There are many variation passages of sum passages available there are many variation passages take some moment available.'}</p>
            </div>
        </div>
	)
}

export default SingleService