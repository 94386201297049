import React from 'react';
import ErrorContent from '../components/Error';


const Error = () => {
    return (
        <React.Fragment>
            <ErrorContent />
        </React.Fragment>
    );
}


export default Error;

