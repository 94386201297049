import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Header from '../../components/Header';

import blogImg1 from '../../assets/img/blog/1.jpg';

const HomeBanner = () => {

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    dots: false,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div id="tcd-banner" className="tcd-banner">

                <Header />

                <div className="banner-wrapper">
                    <div className="container">
                        <div className="banner-slider">
                            <Slider {...sliderSettings}>
                                <div className="banner-content text-center">
                                    <h2 className="banner-title uppercase">
                                        We are Financial Experts Boost Your Business! Right Now
                                    </h2>
                                    <h5 className="banner-desc">Over 24 years experience and knowledge international...</h5>
                                    <div className="banner-btn">
                                        <Link to="/" className="primary-btn mr-30">Read More</Link><Link to="/" className="primary-btn transparent">View Details</Link>
                                    </div>
                                </div>
                                <div className="banner-content text-center">
                                    <h2 className="banner-title uppercase">
                                        We are Financial Experts Boost Your Business! Right Now
                                    </h2>
                                    <h5 className="banner-desc">Over 24 years experience and knowledge international...</h5>
                                    <div className="banner-btn">
                                        <Link to="/" className="primary-btn mr-30">Read More</Link><Link to="/" className="primary-btn transparent">View Details</Link>
                                    </div>
                                </div>
                                <div className="banner-content text-center">
                                    <h2 className="banner-title uppercase">
                                        We are Financial Experts Boost Your Business! Right Now
                                    </h2>
                                    <h5 className="banner-desc">Over 24 years experience and knowledge international...</h5>
                                    <div className="banner-btn">
                                        <Link to="/" className="primary-btn mr-30">Read More</Link><Link to="/" className="primary-btn transparent">View
                                        Details</Link>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HomeBanner;