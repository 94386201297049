import React from 'react';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quoteImg from '../../assets/img/arrows/quote.png';

import testiImg1 from '../../assets/img/team/1.png';
import testiImg2 from '../../assets/img/team/2.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {

                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <div id="tcd-testimonial" className="tcd-testimonial">
                <div className="container">
                    <div className="testimonial-area testi-watermark-text">
                        <div className="testi-slider"> 
                            <Slider {...testimonialSettings}>
                                <SingleTestimonial 
                                    itemClass='testi-item'
                                    itemImg={testiImg1}
                                    quoteImg={quoteImg}
                                    Title='Kori Anderson'
                                    Designation='Founder'
                                    Desc='Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem ipsum...'
                                />
                                <SingleTestimonial 
                                    itemClass='testi-item'
                                    itemImg={testiImg2}
                                    quoteImg={quoteImg}
                                    Title='Kori Anderson'
                                    Designation='Founder'
                                    Desc='Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem ipsum...'
                                />
                                <SingleTestimonial 
                                    itemClass='testi-item'
                                    itemImg={testiImg1}
                                    quoteImg={quoteImg}
                                    Title='Kori Anderson'
                                    Designation='Founder'
                                    Desc='Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem ipsum...'
                                />
                                <SingleTestimonial 
                                    itemClass='testi-item'
                                    itemImg={testiImg2}
                                    quoteImg={quoteImg}
                                    Title='Kori Anderson'
                                    Designation='Founder'
                                    Desc='Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt Neque porro quisquam est, qui dolorem ipsum...'
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial